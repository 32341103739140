import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
}                                     from 'react';
import Select, {
  components,
}                                     from 'react-select';
import {
  useQueryParam,
  StringParam,
}                                     from 'use-query-params';
import BetaFeatures                   from './components/BetaFeatures';
import LoginAs                        from './components/LoginAs';
import PhoneProvision                 from './components/PhoneProvision';
import PhoneMover                     from './components/PhoneMover';
import WhatsappPhoneEnable            from './components/WhatsappPhoneEnable';
import ConferenceList                 from './components/ConferenceList';
import MessagingPricingEditor         from './components/MessagingPricingEditor';
import EmailSync                      from './components/EmailSync';
import EmlDownloader                  from './components/EmlDownloader';
import Toggle                         from '../../components/Toggle';

const sortDealers = dealers => dealers
  ?.sort((d1, d2) => (d1.friendly_name || d1.domain || '').localeCompare(d2.friendly_name || d2.domain || ''));

const sortUsers = users => users
  .sort((u1, u2) => `${u1.first_name}${u1.last_name}`.localeCompare(`${u2.first_name}${u2.last_name}`));

const loadDefinitions = async () => {
  return await fetch('/api/mapi/definitions/v1')
    .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)));
}

const loadAllDealers = async () => {
  const q = {active: true};
  const project = '_id=1,friendly_name=1,domain=1,twilio_subaccount_credentials.sid=1,disable_login_as=1';
  let has_more = false;
  let skip = 0;
  let dealers = [];
  do {
    const [next_dealers, next_has_more] = await fetch(`/api/mapi/dealers?q=${encodeURIComponent(JSON.stringify(q))}&skip=${skip}&project=${encodeURIComponent(project)}`)
      .then(res => res.ok ? (
        Promise.all([res.json(), res.headers.get('mapp-has-more')])
      ) : (
        res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`))
      ));
    skip += 50;
    has_more = next_has_more === 'true';
    dealers = dealers.concat(next_dealers);
  } while(has_more);
  return dealers;
};

const loadAllUsers = async dealer_id => {
  const all_users = [];
  let has_more = false, skip = 0;
  do {
    const params = new URLSearchParams({
      q: JSON.stringify({active: true}),
      skip,
      limit: 50,
      sort: '-_id',
      project: '_id=1,first_name=1,last_name=1,role=1,plans=1,debug_mode=1,email=1,email_settings.incoming.sync_status=1,email_settings.incoming.last_successful_renovation',
    });
    const [r, next_has_more] = await fetch(`/api/mapi/dealers/${dealer_id}/users?${params}`)
      .then(res => res.ok ? res.json().then(d => [d, res.headers.get('mapp-has-more') === 'true']) : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)));
    skip += r.length;
    has_more = next_has_more;
    all_users.push(...r);
  } while (has_more);

  return all_users;
};

const patchDebugMode = (dealer_id, user_id, activate) => fetch(`/api/mapi/dealers/${dealer_id}/users/${user_id}`, {
  method: 'PATCH',
  headers: {'Content-Type': 'application/json'},
  body: JSON.stringify([{op: 'replace', path: `/debug_mode`, value: activate}]),
})
  .then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)));

const SingleValue = ({ children, ...props }) => {
  const user_id = props.data.value;
  const roles = props.selectProps.roles;
  const users = props.selectProps.users;
  const user = users.find(u => u._id === user_id);
  const user_role = user && roles?.find(u => u._id === user.role);
  return (
    <div>
      <components.SingleValue {...props}>
        {children} ({user_role && user_role.name})
      </components.SingleValue>
    </div>
  )
};

const Option = ({ children, ...props }) => {
  const user_id = props.data.value;
  const roles = props.selectProps.roles;
  const users = props.selectProps.users;
  const user = users.find(u => u._id === user_id);
  const user_role = user && roles.find(u => u._id === user.role);
  return (
    <div>
      <components.Option {...props}>
        {children} ({user_role && user_role.name})
      </components.Option>
    </div>
  );
};

const Home = ({ user }) => {
  document.title = 'Walcu Penguin';

  const [ definitions, setDefinitions ] = useState();

  const [ dealers, setDealers ] = useState();
  const [ selected_dealer_id, setSelectedDealerId ] = useQueryParam('dealer_id', StringParam);
  const dealerSelectorRef = useRef(null);

  const selected_dealer = useMemo(() => dealers?.find(d => d._id === selected_dealer_id), [selected_dealer_id, dealers]);

  const dealer_options = useMemo(() => dealers?.map(d => ({
    label: `${d.friendly_name || d.domain} (${d._id})`,
    value: d,
  })),[dealers]);


  const [ login_as_enabled, setLoginAsEnabled ] = useState();

  const patchDisableLoginAs = useCallback(async value => selected_dealer_id && fetch(`/api/mapi/dealers/${selected_dealer_id}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify([{op: 'replace', path: `/disable_login_as`, value}]),
  })
    .then(r => r.ok
      ? r.json()
      : r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`))
    )
  , [selected_dealer_id]);

  useEffect(() => {
    if (!selected_dealer) return;
    setLoginAsEnabled(!selected_dealer.disable_login_as);
  }, [selected_dealer]);

  const [ users, setUsers ] = useState({options: null, users: []});
  const [ selected_user_id, setSelectedUserId ] = useQueryParam('user_id', StringParam);

  const updateSelectedUserId = useCallback(user_id => {
    if (!selected_dealer_id) return;
    if (user_id) window.localStorage.setItem(`dealer_${selected_dealer_id}_preferred_user`, user_id);
    setSelectedUserId(user_id);
  }, [selected_dealer_id, setSelectedUserId]);

  const selected_user = useMemo(() => users.users?.find(u => u._id === selected_user_id), [selected_user_id, users]);

  const [ roles, setRoles ] = useState([]);
  const [ debug_mode, setDebugMode ] = useState(false);

  useEffect(() => {
    if (!selected_dealer_id) return;
    fetch(`/api/mapi/dealers/${selected_dealer_id}/roles`)
      .then(res => res.ok
        ? res.json()
        : res.text().then(t => Promise.reject(`Unexpected ${res.status} at ${res.url}: ${t}`))
      )
      .then(roles => setRoles(roles));
    return () => setRoles();
  }, [ selected_dealer_id ]);

  useEffect(() => {
    if (selected_user_id && users?.users?.length > 0)
      setDebugMode(users.users.find(u => u._id === selected_user_id)?.debug_mode);
  }, [ users, selected_user_id ]);

  useEffect(() => {
    if (!roles?.length) return;
    if (!selected_dealer_id) return;
    loadAllUsers(selected_dealer_id).then(users => {
      const adminIds = roles
        .filter(role => role.active_permission_groups.includes('super_admin'))
        .map(role => role._id);
      const preferred_user_id = window.localStorage.getItem(`dealer_${selected_dealer_id}_preferred_user`);
      const default_user_id = adminIds && adminIds[0] &&
        users.filter(u => adminIds.includes(u.role)  && u.plans.includes('base')).map(f => f._id).find(f => f) ||
        users[0]._id;
      updateSelectedUserId(preferred_user_id || default_user_id);
      const user_options = sortUsers(users).map(d => ({label: `${d.role === 'dealer_admin' ? '* ' : ''}${d.first_name} ${d.last_name}`, value: d._id}));
      setUsers({
        users,
        options: user_options,
      });
    });
  }, [ selected_dealer_id, updateSelectedUserId, roles ])

  useEffect(() => {
    loadDefinitions().then(setDefinitions)
  }, [setDefinitions]);

  useEffect(() => {
    if (dealerSelectorRef.current)
      dealerSelectorRef.current.focus();
    loadAllDealers().then(d => setDealers(sortDealers(d)));
  }, []);

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    gap: '1em',
    padding: '1em',
  }}>
    <div style={{
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: '1em',
    }}>
      <div style={{
        display: 'flex',
        width: '100%',
        gap: '1em',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}>
        <Select
          styles={{
            container: base => ({ ...base, minWidth: '24em' }),
          }}
          isClearable
          onChange={e => {
            setSelectedDealerId(e ? e.value?._id : undefined);
            setRoles();
            updateSelectedUserId();
          }}
          options={dealer_options}
          isLoading={!dealer_options}
          ref={dealerSelectorRef}
          value={dealer_options?.find(d => d?.value?._id === selected_dealer_id)}
          filterOption={(candidate, input) => {
            // Match by friendly_name, domain, id or twilio_subaccount_credentials.sid
            if(!input) return true;
            const dealer = candidate?.value;
            const search = input.toLowerCase();
            return (dealer?.friendly_name || '').toLowerCase().includes(search)
              || (dealer?.domain || '').toLowerCase().includes(search)
              || (dealer?._id || '').toLowerCase().includes(search)
              || (dealer?.twilio_subaccount_credentials?.sid || '').toLowerCase().includes(search);
          }}
          placeholder={'Seleccionar dealer'}
        />
        {selected_dealer_id && (<>
          {user.permissions.includes('Master&Commander') ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
              LoginAs habilitado
              <Toggle
                checked={login_as_enabled}
                onChange={() => {
                  patchDisableLoginAs(login_as_enabled)
                    .then(new_dealer => setDealers(old_dealers => old_dealers.map(d => {
                      if (d._id !== selected_dealer_id) return d;
                      return new_dealer;
                    })))
                    .catch(err => {
                      alert(`Error ${login_as_enabled ? 'des' : ''}habilitando el loginAs: ${err.toString()}`);
                      Promise.reject(err.toString());
                    });
                }}
              />
            </div>
          ) : null}
          <Select
            styles={{
              container: base => ({...base, minWidth: '24em'}),
            }}
            onChange={e => updateSelectedUserId(e.value)}
            options={users.options || []}
            isLoading={!users?.options}
            placeholder={'Seleccionar usuario'}
            key={selected_dealer_id}
            roles={roles}
            users={users.users}
            components={{SingleValue, Option}}
            value={users.options?.find(u => u.value === selected_user_id)}
          />
          {user.permissions.includes('LoginAs') && (
            <LoginAs dealer_id={selected_dealer_id} user_id={selected_user_id} login_as_enabled={login_as_enabled} />
          )}
          {selected_user_id && (
            <div style={{display: 'flex', alignItems: 'center', gap: '0.5em'}}>
              <span>Reporte de errores</span>
              <Toggle
                checked={debug_mode}
                onChange={() => patchDebugMode(selected_dealer_id, selected_user_id, !debug_mode)
                .then(() => setDebugMode(!debug_mode))
                .catch(err => {alert(`Error ${!debug_mode ? 'activando' : 'desactivando'} reporte de errores: `.concat(err.toString())); Promise.reject(err.toString());})
                }
              />
            </div>
          )}
        </>)}
      </div>
      {selected_dealer_id && (
        <BetaFeatures
          dealer_id={selected_dealer_id}
          definitions={definitions}
        />
      )}
    </div>
    <div>
      {selected_dealer_id && (<>
        <div key={selected_dealer_id} style={{
          display: 'flex',
          height: '100%',
          flex: 1,
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          gap: '1em',
        }}>
          {user.permissions.includes('Twilio') &&
            <fieldset style={{maxWidth: '48em', display: 'flex', flexDirection: 'column', gap: '1em'}}>
              <legend>Twilio</legend>
              <div style={{margin: '0'}}>
                ID de Twilio: <pre style={{display: 'inline'}}>{selected_dealer?.twilio_subaccount_credentials.sid}</pre>
              </div>
              <div>
                <h4>Provisionar teléfonos de Twilio:</h4>
                <PhoneProvision dealer_id={selected_dealer_id} />
              </div>
              <div>
                <h4>Mover número a esta cuenta</h4>
                <PhoneMover dealer_id={selected_dealer_id} />
              </div>
              <div>
                <h4>Activar número de whatsapp</h4>
                <WhatsappPhoneEnable dealer_id={selected_dealer_id} />
              </div>
              <div>
                <h4>Llamadas en curso</h4>
                <ConferenceList dealer_id={selected_dealer_id} />
              </div>
            </fieldset>
          }
          <div style={{display: 'flex', flexDirection: 'column', gap: '0.5em'}}>
            <fieldset>
              <legend>E-mails</legend>
              <div style={{display: 'flex', flexDirection: 'column', gap: '1em'}}>
                <div>
                  <h4>Decargar .eml</h4>
                  <EmlDownloader dealer_id={selected_dealer_id} />
                </div>
                <div>
                  <h4>Sincronización de emails</h4>
                  <EmailSync dealer_id={selected_dealer_id} user={selected_user} />
                </div>
              </div>
            </fieldset>
            {user.permissions.includes('Billing') && (
              <fieldset>
                <legend>Billing</legend>
                <MessagingPricingEditor dealer_id={selected_dealer_id} />
              </fieldset>
            )}
          </div>
        </div>
      </>)}
    </div>
  </div>
};

export default Home;
